import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import AvailablePositions from "../components/careers/available-positions";

export default function Careers({ data }) {
  const page = data.page;
  return (
    <Layout>
      <Seo seo={page.seo} />

      <div className="page__featured-image__wrapper ">
        <img
          className="page__featured-image"
          src={page?.featuredImage?.node?.sourceUrl}
          alt=""
        />
        <h2 className="page__title">{page.title}</h2>
      </div>
      <div className="wysiwyg__content__wrapper pt-6 pb-6">
        <div
          className="wysiwyg__content"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </div>

      <div className="wysiwyg__content__wrapper">
        <AvailablePositions jobs={data.jobPosts.nodes} />
      </div>

      <div className="mb-20" />
    </Layout>
  );
}

export const query = graphql`
  {
    page: wpPage(slug: { eq: "careers" }) {
      title
      featuredImage {
        node {
          sourceUrl
        }
      }
      content
      seo {
        fullHead
      }
    }
    jobPosts: allWpJobPost {
      nodes {
        title
        uri
        jobPost {
          jobContext
          jobProfile
          jobPurpose
          keyResponsibilities
          summary {
            directReport
            jobTitle
          }
        }
      }
    }
  }
`;
