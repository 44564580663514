import React from "react";
import { Link } from "gatsby";

export default function AvailablePositions({ jobs, title }) {
  return (
    <>
      <h3 className="careers__title">Available positions</h3>

      <div className="careers__job-posts">
        {jobs.length > 0 ? (
          jobs.map((job, index) => (
            <Link
              key={index}
              to={`/careers${job.uri}`}
              className="careers__job-post"
            >
              <p className="careers__job-post__title text-blue">{job.title}</p>
              <p className="careers__job-post__body">
                <>{job.jobPost.jobPurpose}</>
              </p>
            </Link>
          ))
        ) : (
          <>
            <p className="page__text mb-2">
              {title
                ? `There are currently no open positions for ${title}.`
                : `There are currently no open positions. Please check back later.`}
            </p>
          </>
        )}
      </div>
    </>
  );
}
